import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from '../views/signin/Landing.vue'
import SignIn from '../views/signin/SignIn.vue'
import Unauthorized from '../views/error/Unauthorized.vue'
import Forbidden from '../views/error/Forbidden.vue'
import NotFound from '../views/error/NotFound.vue'
import NoInternet from '../views/error/NoInternet.vue'
import Dashboard from '../views/dashboard/DashBoard.vue'
import MenuList from '../views/menu/menuList.vue'
import MenuGroupList from '../views/menu/menugroupList.vue'
import UserList from '../views/user/userList.vue'
import UserRoleList from '../views/user/userRoleList.vue'
import Profile from '../views/user/profile.vue'
import Password from '../views/user/password.vue'
import AppSettings from '../views/settings/appSettings.vue'
import BrowseList from '../views/user/browseList.vue'
import ErrorLogList from '../views/user/errorList.vue'
import Faq from '../views/settings/faq.vue'
import Contacts from '../views/settings/contacts.vue'
import store from '../store/index'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    name:'Landing',
    component:Landing,
    meta:{title: 'Landing | '+store.getters['settings/siteTitle']}
  },
  {
    path:'/signIn',
    name:'SignIn',
    component:SignIn,
    meta:{title: 'Sign In | '+store.getters['settings/siteTitle']}
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: Unauthorized,
    meta:{title: 'Unauthorized | '+store.getters['settings/siteTitle']}
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: Forbidden,
    meta:{title: 'Forbidden | '+store.getters['settings/siteTitle']}
  },
  {
    path: '/notFound',
    name: 'NotFound',
    component: NotFound,
    meta:{title: 'NotFound | '+store.getters['settings/siteTitle']}
  },
  {
    path: '/noInternet',
    name: 'NoInternet',
    component: NoInternet,
    meta:{title: 'No Internet | '+store.getters['settings/siteTitle']}
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta:{title: 'Dashboard | '+store.getters['settings/siteTitle']}
  },
  {
    path: '/menu/menuList',
    name: 'MenuList',
    component: MenuList,
    meta:{title: 'All Menu | '+store.getters['settings/siteTitle']}
  },
  {
    path: '/menu/menuGroupList',
    name: 'MenuGroupList',
    component: MenuGroupList,
    meta:{title: 'All Menu Group | '+store.getters['settings/siteTitle']}
  },
  {
    path: '/user/userList',
    name: 'UserList',
    component: UserList,
    meta:{title: 'Users | '+store.getters['settings/siteTitle']}
  },
  {
    path: '/user/userRoleList',
    name: 'UserRoleList',
    component: UserRoleList,
    meta:{title: 'User Roles | '+store.getters['settings/siteTitle']}
  },
  {
    path: '/user/profile',
    name: 'Profile',
    component: Profile,
    meta:{title: 'User Profile | '+store.getters['settings/siteTitle']}
  },
  {
    path: '/user/password',
    name: 'Password',
    component: Password,
    meta:{title: 'Change Password | '+store.getters['settings/siteTitle']}
  },
  {
    path: '/settings/appSettings',
    name: 'AppSettings',
    component: AppSettings,
    meta:{title: 'Settings | '+store.getters['settings/siteTitle']}
  },
  {
    path: '/user/browseList',
    name: 'BrowseList',
    component: BrowseList,
    meta:{title: 'Browsing Logs | '+store.getters['settings/siteTitle']}
  },
  {
    path: '/user/errorList',
    name: 'ErrorLogList',
    component: ErrorLogList,
    meta:{title: 'Error Logs | '+store.getters['settings/siteTitle']}
  },
  {
    path: '/settings/faq',
    name: 'Faq',
    component: Faq,
    meta:{title: 'FAQ | '+store.getters['settings/siteTitle']}
  },
  {
    path: '/settings/contacts',
    name: 'Contacts',
    component: Contacts,
    meta:{title: 'Contacts | '+store.getters['settings/siteTitle']}
  },
]

const DEFAULT_TITLE = store.getters['settings/siteTitle'];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  //console.log(store.getters['settings/siteTitle'])
  NProgress.start()
  NProgress.inc(0.1);
  if(to.name==null){
    const objErrorLog={
      status:404,
      statusText:'NotFound',
      url :to.fullPath,
      message:'Page not exists'
    }
    store.dispatch('settings/createErrorLog',objErrorLog)
    next({name:'NotFound'})
  }
  /* else if(to.name!='SignIn' && localStorage.getItem('token')==null){
    next({name:'SignIn'})
  } */
  else{
    next()
  }
})
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
});
  setTimeout(() => NProgress.done(), 500)
})

export default router
