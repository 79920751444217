<template>
    <v-app>
        <nav>
            <v-navigation-drawer
                v-model="drawer"
                app
                dark
                temporary
            >
                <v-list-item class="pb-2">
                    <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        {{this.siteName}}
                    </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list dense>
                    <v-list-item
                        v-for="([icon, text, link], i) in items"
                        :key="i"
                        link
                        @click="$vuetify.goTo(link)"
                    >
                        <v-list-item-icon class="justify-center">
                            <v-icon>{{ icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="subtitile-1">
                            {{text}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list>
                    <v-list-item @click="switchSignIn">
                        <v-list-item-icon class="justify-center">
                            <v-icon>login</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="subtitile-1">
                            {{$t('signIn')}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list> 
            </v-navigation-drawer>
            <v-app-bar app elevation="14">  
                <v-toolbar-title><v-img :src="this.logoPath" max-height="50" max-width="70" contain /></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-app-bar-nav-icon v-if="isXs==true" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                <div v-else>
                    <v-btn text @click="$vuetify.goTo('#home')"><span class="text-capitalize">{{$t('home')}}</span></v-btn>
                    <v-btn text @click="$vuetify.goTo('#features')"><span class="text-capitalize">{{$t('features')}}</span></v-btn>
                    <v-btn text @click="$vuetify.goTo('#contact')"><span class="text-capitalize">{{$t('contact')}}</span></v-btn>
                </div>
                <v-spacer v-if="isXs==false"></v-spacer>
                <v-btn v-if="isXs==false" outlined @click="switchSignIn" class="text-capitalize">{{$t('signIn')}}</v-btn>                              
            </v-app-bar>
        </nav>
        <v-main>
            <Message/>
            <section id="home">            
                <v-row justify="center" class="homeMargin">
                    <v-col cols="10">
                        <v-row>
                            <v-col cols="12" md="6" align-self="center">
                                <h1 class="header">{{this.settingsObj.homeHeader1}}</h1>
                                <div class="bodyText">{{this.settingsObj.homeDetail1}}</div>
                                <v-btn outlined @click="switchSignIn" class="text-capitalize">{{$t('getRegister')}}</v-btn>
                            </v-col>
                            <v-col cols="12" md="6" align-self="start">
                                <v-img max-height="450" max-width="900" :src="getHomeImg"></v-img>
                            </v-col>
                        </v-row>
                        
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="10" md="7">
                        <h1 class="header text-center">{{this.settingsObj.homeHeader2}}</h1>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="10" md="10">
                        <p class="bodyText text-center">{{this.settingsObj.homeDetail2}}</p>
                    </v-col>
                </v-row>
                <v-row justify="center" class="mt-16 mb-16">
                    <v-col cols="10">
                        <v-row>
                            <v-col cols="12" sm="6" md="3">
                                <v-card shaped elevation="16" min-height="185px">
                                    <v-card-title class="grey darken-3 white--text"><v-row justify="center">{{this.settingsObj.homeBox1Header}}</v-row></v-card-title>
                                    <v-card-text>{{this.settingsObj.homeBox1Detail}}</v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-card shaped elevation="16" min-height="185px">
                                    <v-card-title class="grey darken-3 white--text"><v-row justify="center">{{this.settingsObj.homeBox2Header}}</v-row></v-card-title>
                                    <v-card-text>{{this.settingsObj.homeBox2Detail}}</v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-card shaped elevation="16" min-height="185px">
                                    <v-card-title class="grey darken-3 white--text"><v-row justify="center">{{this.settingsObj.homeBox3Header}}</v-row></v-card-title>
                                    <v-card-text>{{this.settingsObj.homeBox3Detail}}</v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-card shaped elevation="16" min-height="185px">
                                    <v-card-title class="grey darken-3 white--text"><v-row justify="center">{{this.settingsObj.homeBox4Header}}</v-row></v-card-title>
                                    <v-card-text>{{this.settingsObj.homeBox4Detail}}</v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </section>
            <section id="features" class="mt-16 mb-16">
                <v-row justify="center">
                    <v-col cols="10">
                        <v-row>
                            <v-col cols="12" md="6" align-self="center" order="1">
                                <v-card flat>
                                    <v-card-title><h3 class="headerFeature">{{this.settingsObj.feature1Header}}</h3></v-card-title>
                                    <v-card-subtitle>
                                        {{this.settingsObj.feature1Detail}}
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6" order="2">
                                <v-img max-width="500" max-height="500" :src="getFeature1Img" contain></v-img>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" order="2" order-md="1">
                                <v-img max-width="500" max-height="500" :src="getFeature2Img"></v-img>
                            </v-col>
                            <v-col cols="12" md="6" align-self="center" order="1" order-md="2">
                                <v-card flat>
                                    <v-card-title><h3 class="headerFeature">{{this.settingsObj.feature2Header}}</h3></v-card-title>
                                    <v-card-subtitle>
                                        {{this.settingsObj.feature2Detail}}
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" align-self="center" order="1">
                                <v-card flat>
                                    <v-card-title><h3 class="headerFeature">{{this.settingsObj.feature3Header}}</h3></v-card-title>
                                    <v-card-subtitle>
                                        {{this.settingsObj.feature3Detail}}
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6" order="2">
                                <v-img max-width="500" max-height="500" :src="getFeature3Img"></v-img>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" order="2" order-md="1">
                                <v-img max-width="500" max-height="500" :src="getFeature4Img"></v-img>
                            </v-col>
                            <v-col cols="12" md="6" align-self="center" order="1" order-md="2">
                                <v-card flat>
                                    <v-card-title><h3 class="headerFeature">{{this.settingsObj.feature4Header}}</h3></v-card-title>
                                    <v-card-subtitle>
                                        {{this.settingsObj.feature4Detail}}
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </section>

            <section id="register" class="mt-16 mb-16">
                <v-card class="white--text text-center" dark>
                    <v-card-text class="pt-10">{{this.settingsObj.registrationText}}</v-card-text>
                    <v-row class="pb-10" justify="center"><v-card-actions><v-btn @click="switchSignIn" class="text-capitalize" outlined>{{$t('registerNow')}}</v-btn></v-card-actions></v-row>
                </v-card>
            </section>

            <section id="contact" class="mt-16 mb-16">
                <v-row justify="center">
                    <v-col cols="10">
                        <v-row>
                             <v-col cols="12" sm="5">
                                <h1>{{$t('contactUs')}}</h1>
                                <h4 class="font-weight-light mt-3">
                                    {{this.settingsObj.contactUsText}}
                                </h4>                               
                                <h4 class="font-weight-light mt-3">
                                    {{$t('telephone')}}: {{this.settingsObj.contactUsTelephone}}
                                </h4>
                                <h4 class="font-weight-light">
                                    {{$t('email')}}: {{this.settingsObj.contactUsEmail}}
                                </h4>
                            </v-col>
                            <v-col cols="12" sm="7">
                                <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                                    <v-text-field
                                        v-model="name"
                                        :rules="nameRules"
                                        :label="$t('name')"
                                        required
                                    ></v-text-field>

                                    <v-text-field
                                        v-model="email"
                                        :rules="emailRules"
                                        :label="$t('email')"
                                        required
                                    ></v-text-field>

                                    <v-textarea
                                        v-model="textArea"
                                        :rules="textAreaRules"
                                        :label="$t('message')"
                                        required
                                    />

                                    <v-btn
                                        :disabled="!valid"
                                        color="primary"
                                        :dark="valid"
                                        rounded
                                        block
                                        class="mt-3"
                                        @click="submit"
                                    >
                                    {{$t('submit')}}
                                    </v-btn>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </section>
        </v-main>
        <v-footer
            dark
            padless
        >
            <v-card
            flat
            tile
            width="100%"
            class="text-center"
            >
            <v-card-text>
                <v-btn
                v-for="icon in icons"
                :key="icon.img"
                :href="icon.route"
                class="mx-4 white--text"
                icon
                >
                <v-icon size="24px">
                    {{ icon.img }}
                </v-icon>
                </v-btn>
            </v-card-text>

            <v-card-text class="pt-0">
                {{this.settingsObj.footerText}}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text class="white--text">
                {{ new Date().getFullYear() }} — <strong>{{this.siteName}}</strong>
            </v-card-text>
            </v-card>
        </v-footer>
    </v-app>
</template>

<script>
import config from '../../config'
import Message from '../../components/common/Message'
import i18n from '@/plugins/i18n'

export default{
    name: "Landing",
    components: { Message },
    data() {
        return {
            drawer: null,
            isXs: false,
            items: [
                ["mdi-home-outline", "Home", "#home"],
                ["featured_play_list", "Features", "#features"],
                ["contact_support", "Contact", "#contact"],
            ],
            icons: [              
                { img:'mdi-facebook',text: 'facebook', route: ''},
                { img:'mdi-twitter',text: 'youtube', route: ''},
                { img:'mdi-linkedin',text: 'linkedin', route: ''},
                { img:'mdi-instagram',text: 'instagram', route: ''},
            ],
            logoPath: "",          
            siteName: "",
            defaultLogoUrl: window.location.origin + "/upload/logo-quick-test-black.png",
            valid: true,
            name: "",
            nameRules: [
                (v) => !!v || this.$t('nameEmptyRuleLanding'),
                (v) => (v && v.length >= 6) || this.$t('nameCharacterRuleLanding'),
            ],
            email: "",
            emailRules: [
                (v) => !!v || this.$t('emailEmptyRuleLanding'),
                (v) => /.+@.+\..+/.test(v) || this.$t('emailWrongRuleLanding'),
            ],
            textArea: "",
            textAreaRules: [
                (v) => !!v || this.$t('messageEmptyRuleLanding'),
                (v) => (v && v.length >= 10) || this.$t('messageCharacterRuleLanding'),
            ],
            lazy: false,
            settingsObj:{}
        };
    },
    methods: {
        onResize() {
            this.isXs = window.innerWidth < 850;
        },
        switchSignIn() {
            this.$router.push({ name: "SignIn" });
        },
        initializeText(){
            this.$store.dispatch('settings/fetchSiteSettings')
            .then((response)=>{
                //console.log(response.data)
                this.settingsObj=response.data
                this.icons[0].route=this.settingsObj.footerFacebook
                this.icons[1].route=this.settingsObj.footerTwitter
                this.icons[2].route=this.settingsObj.footerLinkedin
                this.icons[3].route=this.settingsObj.footerInstagram       
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        },
        submit() {
            if (this.$refs.form.validate()) {
                const obj = {
                    name: this.name,
                    email: this.email,
                    message: this.textArea
                };
                this.$store.dispatch('settings/createContacts', obj)
                    .then(response => {
                    if (response.status == 200) {
                        this.$root.$emit("message_from_parent", response.data.responseMsg);
                    }
                    else if (response.status == 202) {
                        this.$root.$emit("message_from_parent_long", response.data.responseMsg);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
            }
        },
    },
    watch: {
        isXs(value) {
            if (!value) {
                if (this.drawer) {
                    this.drawer = false;
                }
            }
        },
    },
    mounted() {
        this.onResize();
        window.addEventListener("resize", this.onResize, { passive: true });
    },
    computed:{
        getHomeImg:function(){
            return config.hostname+this.settingsObj.homePicture
        },
        getFeature1Img:function(){
            return config.hostname+this.settingsObj.feature1Picture
        },
        getFeature2Img:function(){
            return config.hostname+this.settingsObj.feature2Picture
        },
        getFeature3Img:function(){
            return config.hostname+this.settingsObj.feature3Picture
        },
        getFeature4Img:function(){
            return config.hostname+this.settingsObj.feature4Picture
        },
    },
    created() {
        i18n.locale=(localStorage.getItem('language')==null || localStorage.getItem('language')=='')?'en':localStorage.getItem('language')
        this.$store.dispatch('dashboard/signOut')
        this.logoPath = this.$store.getters["settings/logoPath"] == "" ? this.defaultLogoUrl : config.hostname + this.$store.getters["settings/logoPath"];
        this.siteName = this.$store.getters["settings/siteTitle"] == "" ? "Assess" : this.$store.getters["settings/siteTitle"];
        this.$store.dispatch('dashboard/signOut')
        this.initializeText()
    },
}
</script>
<style scoped>
    .header{
        font-size: 45px;
        margin-bottom: 20px;
    }
    .headerFeature{
        white-space:pre-wrap;
        word-break:break-word;
    }
    .homeMargin{
        margin-bottom: 150px;
    }
    .bodyText{
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 35px;
    }
    .cardBorder{
        border-color: black;
        border-radius: 1px;
    }
</style>