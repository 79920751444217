<template>
    <v-container>
        <v-btn @click="generatePdf" small outlined>{{$t('pdf')}}</v-btn>
        <vue-excel-xlsx class="btnExcel" :data="itemsBrowse" :columns="excelColumnsBrowser" :filename="'errorLog-table'" :sheetname="'browse'">{{$t('excel')}}</vue-excel-xlsx>
        <v-btn small outlined><download-csv :data="itemsBrowse" name= "errorLog-table.csv">{{$t('csv')}}</download-csv></v-btn>
        <v-data-table
        :headers="headersBrowse"
        :items="itemsBrowse"
        class="elevation-1"
        >
        <template v-slot:no-data>
            <v-btn
            color="primary"
            @click="initialize"
            >
            {{$t('reset')}}
            </v-btn>
        </template>
        </v-data-table>
    </v-container>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default {
    name:'BrowseList',
    data(){
        return{
            headersBrowse:[
                {text:this.$t('dateTime'),value:'dateAdded'},
                {text:this.$t('errorCode'),value:'status'},
                {text:this.$t('errorName'),value:'statusText'},
                {text:this.$t('url'),value:'url'},
                {text:this.$t('message'),value:'message'},
            ],
            itemsBrowse:[],
            excelColumnsBrowser : [
                {label:'Date-Time',field:'dateAdded'},
                {label:'Error Code',field:'status'},
                {label:'Error Name',field:'statusText'},
                {label:'URL',field:'url'},
                {label:'Message',field:'message'},          
           ],
        }
    },
    methods:{
        generatePdf(){
            const doc = new jsPDF()
            doc.autoTable({
            body: this.itemsBrowse,
            columns: [
                {header:'Date-Time',dataKey:'dateAdded'},
                {header:'Error Code',dataKey:'status'},
                {header:'Error Name',dataKey:'statusText'},
                {header:'URL',dataKey:'url'},
                {header:'Message',dataKey:'message'},
            ],
            })
            doc.save('errorLog-table.pdf')
        },
        initialize(){
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('settings/fetchErrorLogs')
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                this.itemsBrowse=response.data
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        },
    },
    created(){
        this.initialize()
    }
}
</script>