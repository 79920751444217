<template>
    <v-container>
        <v-row v-if="roleId==1" no-gutters>
            <v-col class="pl-2" cols="12" sm="3">
                <v-card class="grey lighten-1">
                    <v-card-title>
                        <v-icon x-large left>account_circle</v-icon>       
                    </v-card-title>
                    <v-card-text>{{ $t('total') }} <span class="black--text">{{totalUser}}</span></v-card-text>
                </v-card>
            </v-col>
            <v-col class="pl-2" cols="12" sm="3">
                <v-card class="grey lighten-2">
                    <v-card-title>
                        <v-icon x-large left>verified_user</v-icon>       
                    </v-card-title>
                    <v-card-text>{{ $t('active') }} <span class="black--text">{{activeUser}}</span></v-card-text>
                </v-card>
            </v-col>
            <v-col class="pl-2" cols="12" sm="3">
                <v-card class="grey lighten-3">
                    <v-card-title>
                        <v-icon x-large left>thumb_down_off_alt</v-icon>       
                    </v-card-title>
                    <v-card-text>{{ $t('inActive') }} <span class="black--text">{{inActiveUser}}</span></v-card-text>
                </v-card>
            </v-col>
            <v-col class="pl-2" cols="12" sm="3">
                <v-card class="grey lighten-4">
                    <v-card-title>
                        <v-icon x-large left>admin_panel_settings</v-icon>       
                    </v-card-title>
                    <v-card-text>{{ $t('admin') }} <span class="black--text">{{adminUser}}</span></v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <line-chart v-if="loadDate" :chartdata="dateLineChartdata" :options="chartOptions"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <pie-chart v-if="loadRole" :chartdata="userPiedata" :options="chartOptions"/>
            </v-col>
            <v-col cols="6">
                <bar-chart v-if="loadBrowse" :chartdata="browserBarChartdata" :options="BarChartOptions"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <bar-chart v-if="loadMonth" :chartdata="monthBarChartdata" :options="BarChartOptions"/>
            </v-col>
            <v-col cols="6">
                <bar-chart v-if="loadeYear" :chartdata="yearBarChartdata" :options="BarChartOptions"/>
            </v-col>
        </v-row>
    </v-container>  
</template>

<script>
import LineChart from '../../assets/chart/lineChart'
import BarChart from '../../assets/chart/barChart'
import PieChart from '../../assets/chart/pieChart'

export default {
    name:'Dashboard',
    components:{
        LineChart,
        BarChart,
        PieChart
    },
    data(){
        return{
            totalUser:'',
            activeUser:'',
            inActiveUser:'',
            adminUser:'',
            loadDate:false,
            loadRole:false,
            loadMonth:false,
            loadeYear:false,
            loadBrowse:false,
            userPiedata:null,
            dateLineChartdata: null,
            monthBarChartdata:null,
            yearBarChartdata:null,
            chartOptions:null,
            BarChartOptions:null,
            objUser:null,
            userId:null          
        }
    },
    computed:{
        roleId:function(){           
            return this.$store.getters['dashboard/userInfo'].userRoleId
        }
    },
    mounted () {       
        this.loadDate=false
        this.loadRole=false
        this.loadMonth=false
        this.loadeYear=false
        this.loadBrowse=false
        try {
            this.userId=parseInt(localStorage.getItem('loggedUserId'))

            this.$store.dispatch('dashboard/fetchDateLoginCount',this.userId)
            .then((response)=>{
                if(response.status==200){ 
                    this.dateLineChartdata={               
                        labels: response.data.map(x=>x.date.substr(0,10)),           
                        datasets: [
                            {
                                label: this.$t('dashboardChart4'),
                                backgroundColor: '#42424',
                                data: response.data.map(x=>x.count)                       
                            }
                        ]           
                    }  
                    this.loadDate=true               
                }
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })

            this.$store.dispatch('dashboard/fetchRoleWiseUser',this.userId)
            .then((response)=>{              
                if(response.status==200){          
                    this.userPiedata={                  
                        labels: response.data.map(x=>x.roleName),              
                        datasets: [
                            {
                                label: 'User Count',
                                backgroundColor: '#42424',
                                data: response.data.map(x=>x.count),                      
                            }
                        ]           
                    }
                    this.loadRole=true
                }
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })

            this.chartOptions= {
                responsive: true,
                maintainAspectRatio: false
            },

            this.$store.dispatch('dashboard/fetchBrowserCount',this.userId)
            .then((response)=>{
                if(response.status==200){ 
                    this.browserBarChartdata= {
                        labels: response.data.map(x=>x.browser),
                        datasets: [
                            {
                                label: this.$t('browserWiselogin'),
                                backgroundColor: '#42424',
                                data: response.data.map(x=>x.count)
                            }
                        ]
                    }     
                    this.loadBrowse=true 
                }
            })
            .catch((err)=>{
                console.log(err)
            })

            this.$store.dispatch('dashboard/fetchMonthLoginCount',this.userId)
            .then((response)=>{
                if(response.status==200){ 
                    this.monthBarChartdata= {
                        labels: response.data.map(x=>x.month),
                        datasets: [
                            {
                                label: this.$t('monthWiseLogin'),
                                backgroundColor: '#42424',
                                data: response.data.map(x=>x.count)
                            }
                        ]
                    }     
                    this.loadMonth=true 
                }
            })
            .catch((err)=>{
                console.log(err)
            })

            this.$store.dispatch('dashboard/fetchYearLoginCount',this.userId)
            .then((response)=>{
                if(response.status==200){ 
                    this.yearBarChartdata= {
                        labels: response.data.map(x=>x.year),
                        datasets: [
                            {
                                label: this.$t('yearWiseLogin'),
                                backgroundColor: '#42424',
                                data: response.data.map(x=>x.count)
                            }
                        ]
                    }     
                    this.loadeYear=true 
                }
            })
            .catch((err)=>{
                console.log(err)
            })

            this.BarChartOptions= {
                scales:{
                    yAxes: [{
						ticks: {
							beginAtZero: true
						},
						gridLines: {
							display: true
						}
					}],
                    xAxes: [{
						ticks: {
							beginAtZero: true
						},
						gridLines: {
							display: false
						}
					}]
                },
                responsive: true,
                maintainAspectRatio: false
            }
            this.loaded=true
        } catch (error) {
            console.log(error)
        }
    },
    created(){
        this.$store.dispatch('dashboard/changeComponentKey')
        this.$store.dispatch('dashboard/fetchStatus')
        .then((response)=>{
            if(response.status==200){
                this.totalUser=response.data.totalUser
                this.activeUser=response.data.activeUser
                this.inActiveUser=response.data.inActiveUser
                this.adminUser=response.data.adminUser
            }
        })
        .catch((err)=>{
            console.log(err)
        })
    }
}
</script>

<style scoped>
    .small {
        max-width: 600px;
        margin:  150px auto;
    }
</style>