<template>
  <v-container>
    <Message/>
    <v-btn @click="generatePdf" small outlined>{{$t('pdf')}}</v-btn>
    <vue-excel-xlsx class="btnExcel" :data="itemsMenuGroup" :columns="excelColumnsMenuGroup" :filename="'menuGroup-table'" :sheetname="'menuGroups'">{{$t('excel')}}</vue-excel-xlsx>
    <v-btn small outlined><download-csv :data="itemsMenuGroup" name= "menuGroup-table.csv">{{$t('csv')}}</download-csv></v-btn>
    <v-data-table
      :headers="headersMenuGroup"
      :items="itemsMenuGroup"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          
          <v-dialog
            v-model="dialog"
            max-width="600"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                absolute
                right
              >
              {{$t('newMenuGroup')}}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-form ref="form">
                        <v-text-field
                          v-model="editedItem.menuGroupName"
                          :label="$t('menuGroup')"
                          :rules="[rules.required]"
                          clearable                       
                        ></v-text-field>
                      </v-form>
                    </v-col>                 
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                {{$t('cancel')}}
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                {{$t('save')}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline">{{$t('deleteConfirmMessage')}}</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">{{$t('cancel')}}</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{$t('ok')}}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogMenu" max-width="600">
            <v-card>
              <v-treeview
                activatable
                :items="items"               
              >
                <template slot="label" slot-scope="{ item }">
                  <v-chip :color="getColor(item)"><a @click="assignToGroup(item)">{{ openDialog(item) }}</a></v-chip>                 
                </template>
              </v-treeview>
            </v-card>          
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
        <v-icon
          small
          @click="getMenus(item)"
        >
          menu
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
        {{$t('reset')}}
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Message from '../../components/common/Message'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default {
    name:'MenuGroupList',
    components:{
      Message
    },
    data(){
        return{
          items:[],
          rules:{
            required:value=>!!value||this.$t('required'),
          },         
          dialog:false,
          dialogDelete:false,
          dialogMenu:false,
          headersMenuGroup:[
            {text:this.$t('menuGroup'),value:'menuGroupName'},
            {text:this.$t('actions'), value:'actions', sortable: false },
          ],
          itemsMenuGroup:[],
          editedIndex:-1,
          editedItem:{
            menuGroupID:'',
            menuGroupName:''
          },
          defaultItem:{
            menuGroupID:'',
            menuGroupName:''
          },
          excelColumnsMenuGroup : [
            {label: "Menu Group",field: "menuGroupName"}           
          ],
        }
    },
    methods:{
      generatePdf(){
        const doc = new jsPDF()
        doc.autoTable({
          body: this.itemsMenuGroup,
          columns: [
            { header: 'Menu Group', dataKey: 'menuGroupName' }
          ],
        })
        doc.save('menuGroup-table.pdf')
      },
      openDialog(item){
        //console.log(item)
        return this.$t(item.title)
      },
      getColor(item){
        //console.log(item.isSelected)
        if(item.isSelected==true){
          return 'orange'
        }else{
          return ''
        }
      },
      assignToGroup(item){
        //console.log(item)
        if(item.isSelected!=null){
          const objMenuOperation={
            menuId:item.id,
            menuGroupId:item.groupId,
            isSelected:item.isSelected,
            addedBy:parseInt(localStorage.getItem('loggedUserId')),
          }
          this.$store.dispatch('menu/assignNewMenu',objMenuOperation)
          .then(response=>{
            //console.log(response)
            if(response.status==200){
              this.$root.$emit('message_from_parent',this.$t(response.data.responseMsg))
              if(response.data.status=='success'){
                item.isSelected=true
                this.getColor(item)
              }else if(response.data.status=='delete'){
                item.isSelected=false
                this.getColor(item)
              }
            }else if(response.status==202){
              this.$root.$emit('message_from_parent',this.$t(response.data.responseMsg))
            }
          })
          .catch(err=>{
            console.log(err)
            this.$router.push({name:'NoInternet'})
          })
        }else{
          this.$root.$emit('message_from_parent',this.$t('clickToChildOnly'))
        }
      },
      initialize(){
        this.$store.dispatch('dashboard/applyLoading')
        this.$store.dispatch('menu/fetchMenuGroup')
        .then((response)=>{
          this.itemsMenuGroup=response.data.data
          this.$store.dispatch('dashboard/cancelLoading')
        })
        .catch((err)=>{
          console.log(err)
          this.$router.push({name:'NoInternet'})
        })
      },
      editItem(item){
        //console.log(item)
        this.editedIndex=this.itemsMenuGroup.indexOf(item)
        this.editedItem=Object.assign({},item)
        this.dialog=true
      },
      deleteItem(item){       
        this.editedItem = Object.assign({}, item)
        this.dialogDelete=true
      },
      deleteItemConfirm () {
        this.$store.dispatch('menu/deleteMenuGroup',this.editedItem.menuGroupID)
        .then(response=>{
            //console.log(response)
            if(response.status==200){
              this.$root.$emit('message_from_parent',this.$t(response.data.responseMsg))
              this.$store.dispatch('menu/fetchMenuGroup')
              .then((response)=>{
                this.itemsMenuGroup=response.data.data                  
              })
              .catch((err)=>{
                console.log(err)
              })
            }else if(response.status==202){
              this.$root.$emit('message_from_parent',this.$t(response.data.responseMsg))
            }
          })
          .catch(err=>{
            console.log(err)
            this.$router.push({name:'NoInternet'})
          })
        this.closeDelete()
      },
      getMenus(item){
        this.$store.dispatch('dashboard/applyLoading')
        this.dialogMenu=true
        this.$store.dispatch('menu/fetchAllMenu',item.menuGroupID)
        .then((response)=>{
          if(response.status==200){
            this.items=response.data
          }
          this.$store.dispatch('dashboard/cancelLoading')
        })
        .catch((err)=>console.log(err))
      },
      close(){
        this.dialog=false
        this.$nextTick(()=>{
          this.editedItem=Object.assign({},this.defaultItem)
          this.editedIndex=-1
        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      save () {
        //console.log(this.editedIndex)
        if(this.$refs.form.validate()){
          if (this.editedIndex > -1) {
          //edit
          const objMenuGroup={
            menuGroupID:this.editedItem.menuGroupID,
            menuGroupName:this.editedItem.menuGroupName,
            lastUpdatedBy:parseInt(localStorage.getItem('loggedUserId')),
          }
          this.$store.dispatch('menu/updateMenuGroup',objMenuGroup)
          .then(response=>{
            //console.log(response)
            if(response.status==200){
              this.$root.$emit('message_from_parent',this.$t(response.data.responseMsg))
              this.$store.dispatch('menu/fetchMenuGroup')
              .then((response)=>{
                this.close()
                this.itemsMenuGroup=response.data.data
              })
              .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
              })
            }else if(response.status==202){
              this.$root.$emit('message_from_parent',this.$t(response.data.responseMsg))
            }
          })
          .catch(err=>{
            console.log(err)
          })
        } else {
          //insert
          const objMenuGroup={
            menuGroupName:this.editedItem.menuGroupName,
            addedBy:parseInt(localStorage.getItem('loggedUserId')),
          }
          this.$store.dispatch('menu/createMenuGroup',objMenuGroup)
          .then(response=>{
            //console.log(response)
            if(response.status==200){
              this.$root.$emit('message_from_parent',this.$t(response.data.responseMsg))
              this.$store.dispatch('menu/fetchMenuGroup')
              .then((response)=>{
                this.close()
                this.itemsMenuGroup=response.data.data
              })
              .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
              })
              }else if(response.status==202){
                this.$root.$emit('message_from_parent',this.$t(response.data.responseMsg))
              }
            })
            .catch(err=>{
              console.log(err)
              this.$router.push({name:'NoInternet'})
            })
          }
        
        }
        
      },
    },
    computed:{
      formTitle(){
        return this.editedIndex===-1?this.$t('newMenuGroup'):this.$t('editMenuGroup')
      }
    },
    watch:{
      dialog (val) {         
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created(){
      this.initialize()
    }
}
</script>