<template>
    <nav>
        <v-app-bar app light :color="getAppHeaderColor">
            <v-app-bar-nav-icon @click="drawer=!drawer"></v-app-bar-nav-icon>
            <v-spacer></v-spacer>
            <v-btn v-if="isXs==false" @click="applyLock" icon>
                <v-icon>lock</v-icon>
            </v-btn>
            <v-btn v-if="isXs==false" @click="toggle" icon>
                <v-icon>fullscreen</v-icon>
            </v-btn>
            <v-menu v-if="isXs==false" offset-y>
                <template v-slot:activator="{on}">
                    <v-btn text v-on="on" color="grey">
                        <v-badge :content="browseCount" :value="browseCount" color="primary" overlap>
                            <v-icon>notifications_active</v-icon>
                        </v-badge>
                    </v-btn>
                </template>
                <v-list style="max-height: 300px" class="overflow-y-auto">
                    <v-list-item-group>                   
                        <v-list-item v-for="item in browseFilter" :key="item.logInTime" inactive>                          
                            <v-list-item-icon>
                                <v-icon>send</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{$t('logInTime')}}:{{item.logInTime}}, {{$t('ip')}}:{{item.ip}}, {{$t('browser')}}:{{item.browser}}, {{$t('platform')}}:{{item.platform}}
                                </v-list-item-title>
                            </v-list-item-content>                  
                        </v-list-item>
                    </v-list-item-group>
                </v-list>              
            </v-menu>
            <v-menu offset-y>
                <template v-slot:activator="{on}">
                    <v-btn text v-on="on" color="grey">
                        <v-icon left>expand_more</v-icon>
                        <span class="text-capitalize">{{$t('language')}}</span>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">
                        <v-list-item-title><flag :iso="entry.flag" v-bind:squared=false /> {{entry.title}}</v-list-item-title>
                    </v-list-item>                  
                </v-list>
            </v-menu>
            <v-menu offset-y>
                <template v-slot:activator="{on}">
                    <v-btn text v-on="on" color="grey">
                        <v-icon left>expand_more</v-icon>
                        <span class="text-capitalize">{{$t('personalize')}}</span>
                    </v-btn>
                </template>
                <v-list v-if="getUserRole=='Admin'">
                    <v-list-item v-for="link in linksAdmin" :key="link.text" :to="link.route">
                        <v-list-item-title>{{$t(link.text)}}</v-list-item-title>
                    </v-list-item>                  
                </v-list> 
                <v-list v-else>
                    <v-list-item v-for="link in linksOthers" :key="link.text" :to="link.route">
                        <v-list-item-title>{{$t(link.text)}}</v-list-item-title>
                    </v-list-item>                  
                </v-list>                        
            </v-menu>          
            <v-btn @click.stop="dialog=true" icon>
                <v-icon>logout</v-icon>
            </v-btn>
        </v-app-bar>
        <v-navigation-drawer 
            v-model="drawer"
            app
            dark
            :color="getAppBarColor">
             
            <v-list>
                <v-list-item two-line>
                    <v-list-item-avatar>
                        <img :src=getSrc>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title class="text-capitalize">{{getFullName}}</v-list-item-title>
                        <v-list-item-subtitle><span class="grey--text">{{getUserRole}}</span></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item to="/dashboard">
                    <v-list-item-icon>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{$t('Dashboard')}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-group v-for="item in menus" :key="item.title" :prepend-icon="item.icon" no-action>
                    <template v-slot:activator>  
                        <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>               
                    </template>
                    <v-list-item v-for="child in item.childItems" :key="child.title" :to="child.route">
                        <v-list-item-content>
                            <v-list-item-title>{{ $t(child.title) }}</v-list-item-title> 
                        </v-list-item-content>                                            
                    </v-list-item>
                </v-list-group>
            </v-list>
        </v-navigation-drawer>
        <v-dialog v-model="dialog" max-width="290" dark persistent>
            <v-card>
                <v-card-title class="headline">{{$t('leaveHeader')}}</v-card-title>
                <v-card-text>{{$t('leaveConfirm')}}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialog=false">{{$t('stay')}}</v-btn>
                    <v-btn color="dark" text @click="signOut">{{$t('signOut')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </nav>
</template>

<script>
import config from '../../config'
import i18n from '@/plugins/i18n'

export default {
    name:'Navbar',
    data(){
        return{
            isXs: false,
            fullscreen:false,
            val:'',
            drawer:true,
            linksAdmin: [
                { text: 'Profile', route: '/user/profile'},
                { text: 'Password', route: '/user/password'},
                { text: 'Contacts', route: '/settings/contacts'}
            ],
            linksOthers: [
                { text: 'Profile', route: '/user/profile'},
                { text: 'Password', route: '/user/password'},
            ],
            languages: [
                { flag: 'us', language: 'en', title: 'English' },
                { flag: 'es', language: 'es', title: 'Español' },
                { flag: 'it', language: 'it', title: 'Italiano' },
                { flag: 'in', language: 'in', title: 'हिन्दी' },
                { flag: 'bd', language: 'bn', title: 'বাংলা' },             
            ],
            dialog:false,
            userInfo:null,
            roleId:null,
            token:null,
            menus:[],
            itemsBrowse:null,
            browseFilter:null,
            browseCount:null
        }
    },
    methods:{
        onResize() {
            this.isXs = window.innerWidth < 850;
        },
        changeLocale(locale){
            localStorage.setItem('language', locale)
            i18n.locale = localStorage.getItem('language')
            location.reload()
        },
        applyLock(){
            this.$store.dispatch('dashboard/applyOverlay')
        },
        toggle () {
            this.$fullscreen.toggle()
        },
        signOut:function(){
            this.$store.dispatch('dashboard/signOut')
            .then(()=>{
                this.$router.push({name:'Landing'})
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'Landing'})
            })
        },
        getAppMenu(){
            const info={
                roleId:this.$store.getters['dashboard/userInfo'].userRoleId
            }
            this.$store.dispatch('dashboard/fetchMenu',info)
            .then((response)=>{
                if(response.status==200){
                    this.menus=response.data
                }
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        },
        getBrowseData(){
            this.$store.dispatch('user/fetchNotificationList')
            .then((response)=>{
                this.itemsBrowse=response.data.data
                this.browseFilter=this.itemsBrowse.filter(function(obj){
                    return obj.userId==localStorage.getItem('loggedUserId')
                })
                this.browseCount=this.browseFilter.length
            })
            .catch((err)=>{
                console.log(err)
                this.$router.push({name:'NoInternet'})
            })
        }
    },
    mounted() {
        this.onResize();
        window.addEventListener("resize", this.onResize, { passive: true });
    },
    computed:{
        getSrc:function(){
            return config.hostname+localStorage.getItem('img')
        },
        getFullName:function(){
            return localStorage.getItem('fullName')
        },
        getAppBarColor:function(){
            return this.$store.getters['settings/appBarColor']
        },
        getAppHeaderColor:function(){
            return this.$store.getters['settings/headerColor']
        },
        getUserRole:function(){
            return this.$store.getters['dashboard/userInfo'].roleName
        }
    },
    created(){
        i18n.locale=(localStorage.getItem('language')==null || localStorage.getItem('language')=='')?'en':localStorage.getItem('language')
        if(this.$store.getters['settings/allSettings'].allowFaq==true && this.$store.getters['dashboard/userInfo'].userRoleId!=1){
            this.linksOthers.push({ text: 'FAQ', route: '/settings/faq' })
        }
        this.getAppMenu()
        this.getBrowseData()
    }
}
</script>