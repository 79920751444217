import Vuex from 'vuex'
import Vue from 'vue'
import dashboard from './modules/dashboard'
import user from './modules/user'
import menu from './modules/menu'
import settings from './modules/settings'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export default new Vuex.Store({
    modules:{
        dashboard,
        user,
        menu,
        settings
    },
    plugins:[createPersistedState()],
});