<template>
    <div class="login-page">  
        <Message/>    
        <v-card elevation="10" class="login-box" shaped>
            <v-row v-if="this.logoImg!=''" justify="center" class="pt-2"><v-img @click="switchRegister" :src="this.logoImg" max-height="50" max-width="200" contain></v-img></v-row>
            <v-row v-else justify="center" class="pt-2"><v-img @click="switchRegister" :src="this.defaultLogoUrl" max-height="50" max-width="200" contain></v-img></v-row>
            <v-card-text class="text-center">
                <h2 class="black--text">{{this.title}}</h2>
                <p class="grey--text">{{this.description}}</p>         
            </v-card-text>

            <v-card-text>
                <v-form ref="form">
                    <v-text-field 
                        :label="$t('email')"  
                        v-model="emailLogin" 
                        append-icon="email"
                        :rules="[rules.required,rules.emailRules]"
                        outlined
                        dense
                        rounded
                    >
                    </v-text-field>
                    <v-text-field 
                        :label="$t('password')" 
                        class="mb-10"
                        v-model="password" 
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show ? 'text' : 'password'"                            
                        :hint="$t('passwordLengthMessage')"                                       
                        @click:append="show = !show"
                        outlined
                        dense
                        rounded
                    >
                    </v-text-field>                   
                    <div class="mb-4 text-center font-weight-bold">
                        <a class="text-decoration-none text-uppercase" @click="adminCred">Admin</a> 
                        <a class="text-decoration-none text-uppercase pl-6" @click="userCred">User</a>
                    </div>
                    <v-btn :loading="loading" class="text-capitalize mb-2" @click="submit" rounded block dark>{{$t('signIn')}}</v-btn>
                    <v-btn color="grey lighten-2" class="text-capitalize black--text" @click="register=true" rounded block>{{$t('register')}}</v-btn>
                </v-form>
            </v-card-text>
            <v-card-actions class="mt-6">
                <v-row justify="center">
                    <v-btn text class="text-capitalize grey--text" @click="forget = true">{{$t('forgetPassword')}}</v-btn>
                </v-row>
            </v-card-actions>
            <v-expand-transition>
                <v-card v-if="forget" class="transition-fast-in-fast-out v-card--reveal" style="height: 100%;">
                    <v-row v-if="this.logoImg!=''" justify="center" class="pt-2"><v-img :src="this.logoImg" max-height="50" max-width="200" contain></v-img></v-row>
                    <v-row v-else justify="center" class="pt-2"><v-img :src="this.defaultLogoUrl" max-height="50" max-width="200" contain></v-img></v-row>
                    <v-card-text class="text-center">
                        <h2 class="black--text">{{$t('forgetPassword')}}</h2>                              
                    </v-card-text>
                    <v-card-text class="pb-0">
                        <v-form ref="formForget">
                            <v-text-field
                              v-model="emailForget"
                              :label="$t('email')"
                              :rules="[rules.required,rules.emailRules]"
                              clearable                       
                            ></v-text-field>                                                                               
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="pt-0">
                        <v-btn @click="forget = false" text color="grey darken-4">{{$t('close')}}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="sendPassword" text color="grey darken-4">{{$t('sentPassword')}}</v-btn>                       
                    </v-card-actions>
                </v-card>
            </v-expand-transition>
            <v-expand-transition>
                <v-card v-if="register" class="transition-fast-in-fast-out v-card--reveal" style="height: 100%;">
                    <v-row v-if="this.logoImg!=''" justify="center" class="pt-2"><v-img :src="this.logoImg" max-height="50" max-width="200" contain></v-img></v-row>
                    <v-row v-else justify="center" class="pt-2"><v-img :src="this.defaultLogoUrl" max-height="50" max-width="200" contain></v-img></v-row>                    
                    <v-card-text class="text-center">
                        <h2 class="black--text">{{$t('register')}}</h2>                              
                    </v-card-text>
                    <v-card-text class="pb-0">
                        <v-form ref="formRegister">
                            <v-text-field 
                                :label="$t('name')" 
                                v-model="fullNameRegister"                               
                                :rules="[rules.required]"
                                clearable
                            >
                            </v-text-field>
                            <v-text-field
                              v-model="emailRegister"
                              :label="$t('email')"
                              :rules="[rules.required,rules.emailRules]"
                              clearable                       
                            ></v-text-field>
                            <v-text-field 
                                :label="$t('password')" 
                                v-model="passwordRegister" 
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.min]"
                                :type="show ? 'text' : 'password'"                            
                                :hint="$t('passwordLengthMessage')"                                      
                                @click:append="show = !show"
                            >
                            </v-text-field>                                                                                
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="pt-0">
                        <v-btn @click="register = false" text color="grey darken-4">{{ $t('close') }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="registration" text color="grey darken-4">{{ $t('register') }}</v-btn>                       
                    </v-card-actions>
                </v-card>
            </v-expand-transition>
        </v-card>      
    </div>               
</template>

<script>
import Message from '../../components/common/Message'
import config from '../../config'

export default {
    name:'Signin',
    components:{
        Message
    },
    data(){
        return{
            rules:{
                required:value=>!!value||this.$t('required'),
                min: v => v.length >= 8 ||this.$t('min8Character'),
                emailRules:v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('emailInvalid')
            },
            register: false,
            forget:false,
            emailForget:'',
            fullNameRegister:'',
            emailRegister:'',
            passwordRegister:'',
            emailLogin:'',
            password:'',
            show: false,
            loading:false,
            userInfo:null,
            msg:'',
            userId:null,
            logoImg:'',
            title:'',
            description:'',
            defaultLogoUrl:window.location.origin+'/upload/logo.png',
            settingsData:null
        }
    },
    methods:{
        adminCred(){
            this.emailLogin='admin@vueadmin.com'
            this.password='admin@2023'
        },
        userCred(){
            this.emailLogin='user@vueadmin.com'
            this.password='user@2023'
        },
        switchRegister(){
            this.$router.push({name:'Landing'})
        },
        sendPassword(){
            if(this.$refs.formForget.validate()){
                this.$store.dispatch('user/fetchUserInfo',this.emailForget)
                .then((response)=>{
                    if(response.status==200){
                        const objEmail={
                            toEmail:this.emailForget,
                            logoPath:this.$store.getters['settings/logoPath']==''?this.defaultLogoUrl:config.hostname+this.$store.getters['settings/logoPath'].replace(/\\/g, '/'),
                            siteUrl:window.location.origin,
                            siteTitle:this.title,
                            password:response.data.password
                        }
                        if(this.settingsData.defaultEmail=='' || this.settingsData.password=='' || this.settingsData.host=='' || this.settingsData.port==null){
                            this.$root.$emit('message_from_parent',this.$t('emailSettingsNotDone'))                          
                        }else{
                            this.$store.dispatch('settings/passwordEmailSent',objEmail)
                            this.$root.$emit('message_from_parent',this.$t('pleaseCheckyouremail'))
                        }                       
                    }else if(response.status==202){
                        this.$root.$emit('message_from_parent',this.$t(response.data.responseMsg))
                    }
                })
            } 
        },
        registration(){
            if(this.$refs.formRegister.validate()){
                const objUser={          
                    fullName:this.fullNameRegister,
                    email:this.emailRegister,
                    password:this.passwordRegister          
                }
                this.$store.dispatch('user/createRegistration',objUser)
                .then(response=>{
                    if(response.status==200){
                        const credential={
                            email:this.emailRegister,
                            password:this.passwordRegister
                        }
                        this.logIn(credential)
                        
                        if(this.$store.getters['settings/allSettings'].allowWelcomeEmail==true){                           
                            const objEmail={
                                toEmail:this.emailRegister,
                                name:this.fullNameRegister,
                                logoPath:this.$store.getters['settings/logoPath']==''?this.defaultLogoUrl:config.hostname+this.$store.getters['settings/logoPath'].replace(/\\/g, '/'),
                                siteUrl:window.location.origin,
                                siteTitle:this.title,
                                password:this.passwordRegister,
                            }                           
                            this.$store.dispatch('settings/welcomeEmailSent',objEmail)
                        }
                                        
                    }else if(response.status==202){
                        this.$root.$emit('message_from_parent',this.$t(response.data.responseMsg))
                    }
                })
                .catch(err=>{
                    console.log(err)
                })
            }
        },
        submit(){
            if(this.$refs.form.validate()){
                this.loading=true
                const credential={
                    email:this.emailLogin,
                    password:this.password
                }
                this.logIn(credential)  
            }
        },
        logIn(credential){
            this.$store.dispatch('dashboard/fetchSigninInfo',credential)
                .then(response=>{                                              
                        if(response.status==200){
                            this.userId=parseInt(localStorage.getItem('loggedUserId'))                                                     
                            const objLogHistory={
                                userId:this.userId,
                                ip:this.$store.getters['dashboard/clientInfo'].userIp,
                                browser:this.$store.getters['dashboard/clientInfo'].browserName==null?'others':this.$store.getters['dashboard/clientInfo'].browserName,
                                browserVersion:this.$store.getters['dashboard/clientInfo'].browserVersion,
                                platform:this.$store.getters['dashboard/clientInfo'].platform                             
                            }
                            this.$store.dispatch('dashboard/createLogHistory',objLogHistory)
                            .then((response)=>{
                                if(response.status==200){
                                    this.$store.dispatch('dashboard/changeVisibility')
                                    this.$router.push({name:'Dashboard'})                                                                      
                                }
                            })                                                                                
                        }else if(response.status==204){
                            this.loading=false
                            this.msg=this.$t('incorrectEmailPassword')
                            this.$root.$emit('message_from_parent',this.msg)
                            localStorage.removeItem('token')
                            localStorage.removeItem('loggedUserId')
                            localStorage.removeItem('logCode')
                        }                      
                    })
                .catch(err => {                   
                    if(this.$store.getters['dashboard/authStatus']==='error'){
                        this.loading=false                      
                        this.msg=this.$t('errorNetworkConnection')
                        this.$root.$emit('message_from_parent',this.msg)
                    }
                    console.log(err)
                })
        },
        getSettings(){
            this.$store.dispatch('settings/fetchSiteSettings')
            .then(response=>{
                if(response.status==200){
                    this.settingsData=response.data
                    this.logoImg=response.data.logoPath==''?'':config.hostname+response.data.logoPath.replace(/\\/g, '/')
                    this.title=response.data.siteTitle==''?'Vue Admin':response.data.siteTitle
                    this.description=response.data.welComeMessage==''?'Hello there,Sign in to start your task!':response.data.welComeMessage
                    
                    const favicon = document.getElementById('favicon')
                    favicon.href = config.hostname+response.data.faviconPath.replace(/\\/g, '/')
                }
            })
            .catch(err=>{
                console.log(err)
            })
        },
    },
    created(){
        this.getSettings()
        this.$store.dispatch('dashboard/signOut')
        this.$store.dispatch('dashboard/fetchClientInfo')
        if(localStorage.getItem('logCode')!=null){
            this.$store.dispatch('dashboard/updateLogHistory',localStorage.getItem('logCode'))
        }
    } 
}
</script>

<style scoped>
    .login-page {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 85vh;
    }
    
    .login-box {
        width: 380px;
    }
    .v-card--reveal {
        bottom: 0;
        opacity: 1 !important;
        position: absolute;
        width: 100%;
    }
</style>