import axios from 'axios'
import config from './../../config'

const state={
    status:'',
    token:localStorage.getItem('token')||'',
    user:{},
    confirmation:{},
    roleUserCount:[],
    loginDateData:[],
    loginMonthData:[],
    loginYearData:[],
    loginBrowserData:[],
    menus:[],
    activeUserStatus:{},
    componentKey:0,
    visible:false,
    overlay:false,
    loading:false,
    clientInfo:{}
};

const getters={
    isLoggedIn:state=>!!state.token,
    authStatus:state=>state.status,
    userInfo:state=>state.user,
    menus:state=>state.menus,
    roleUserCount:state=>state.roleUserCount,
    loginDateData:state=>state.loginDateData,
    loginMonthData:state=>state.loginMonthData,
    loginYearData:state=>state.loginYearData,
    loginBrowserData:state=>state.loginBrowserData,
    activeUserStatus:state=>state.activeUserStatus,
    keyValue:state=>state.componentKey,
    visible:state=>state.visible,
    overlay:state=>state.overlay,
    loading:state=>state.loading,
    clientInfo:state=>state.clientInfo
};

const actions={
    fetchSigninInfo({commit},credential){
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Users/GetLoginInfo/${credential.email}/${credential.password}`)
            .then(function(response){
            // handle success
            if(response.status==200){
                const token = 'Bearer '+response.data.token
                localStorage.setItem('token', token)
                localStorage.setItem('loggedUserId', response.data.obj.userId)
                localStorage.setItem('fullName', response.data.obj.fullName)
                localStorage.setItem('img', response.data.obj.imagePath)
                axios.defaults.headers.common['Authorization'] = token
                commit('auth_success', response.data)               
            }
            resolve(response)   
            })
            .catch(function (error) {
            // handle error
                commit('auth_error')
                localStorage.removeItem('token')
                localStorage.removeItem('loggedUserId')
                localStorage.removeItem('fullName')
                localStorage.removeItem('img')
                delete axios.defaults.headers.common['Authorization']
                reject(error)
            })
         })
    },
    signOut({commit}){
        return new Promise((resolve) => {                   
            commit('signOut')
            localStorage.removeItem('token')
            localStorage.removeItem('loggedUserId')
            localStorage.removeItem('fullName')
            localStorage.removeItem('img')           
            delete axios.defaults.headers.common['Authorization']
            resolve()
        })
    },
    fetchMenu({commit},info) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Menu/GetSidebarMenu/${info.roleId}`)
            .then((response)=>{
                commit('setMenu',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchStatus({commit}) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+'/api/Users/UserStatus')
            .then((response)=>{
                commit('setActiveStatus',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    createLogHistory({commit},objLogHistory) {
        return new Promise((resolve,reject)=>{
            axios.post(config.hostname+'/api/Users/CreateLoginHistory',objLogHistory)
            .then((response)=>{
                //console.log(response.data)
                if(response.status==200){
                    localStorage.setItem('logCode',response.data.responseMsg)
                    commit('logHistory',response.data)
                }             
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    updateLogHistory({commit},logCode) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Users/UpdateLoginHistory/${logCode}`)
            .then((response)=>{
                commit('logHistory',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchRoleWiseUser({commit}) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+'/api/Users/GetRoleWiseUser')
            .then((response)=>{
                commit('roleUserCount',response.data)              
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchDateLoginCount({commit},id) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Users/GetLogInSummaryByDate/${id}`)
            .then((response)=>{
                commit('loginCountDate',response.data)              
                //console.log(response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchMonthLoginCount({commit},id) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Users/GetLogInSummaryByMonth/${id}`)
            .then((response)=>{
                commit('loginCountMonth',response.data)
                //console.log(response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchYearLoginCount({commit},id) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Users/GetLogInSummaryByYear/${id}`)
            .then((response)=>{
                commit('loginCountYear',response.data)
                //console.log(response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchBrowserCount({commit},id) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Users/GetBrowserCount/${id}`)
            .then((response)=>{
                commit('browserCount',response.data)
                //console.log(response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    changeComponentKey({commit}){
        commit('setComponentKey')
    },
    changeVisibility({commit}){
        commit('setVisibility')
    },
    applyOverlay({commit}){
        commit('setOverlay')
    },
    clearOverlay({commit}){
        commit('resetOverlay')
    },
    applyLoading({commit}){
        commit('setLoading')
    },
    cancelLoading({commit}){
        commit('resetLoading')
    },
    fetchClientInfo({commit}){
        axios.get('https://api.ipify.org?format=json')
        .then((response)=>{
            const { detect } = require('detect-browser');
            const browser = detect();
            const data={
                userIp:response.data.ip,
                browserName:browser.name,
                browserVersion:browser.version,
                platform:browser.os,
            }
            axios.get('https://ipapi.co/'+response.data.ip+'/json/')
            .then((response)=>{
                console.log(response)
            })

            /* fetch('https://ipapi.co/json/')
            .then(function(response) {
            response.json().then(jsonData => {
                console.log(jsonData);
            });
            })
            .catch(function(error) {
                console.log(error)
            }); */

            axios.get('https://ipapi.co/json/')
            .then((response)=>{
                response.json().then(jsonData => {
                    console.log(jsonData)
                })
            })

            commit('setClientInfo',data)         
        })
    }
};

const mutations ={
    auth_request:(state)=>{
        state.status='loading'
    },
    auth_success:(state, data)=>{
        state.status = 'success'
        state.token = data.token
        state.user = data.obj
    },
    auth_error:(state)=>{
        state.status = 'error'
    },
    signOut:(state)=>{
        state.status = ''
        state.token = ''
        state.componentKey=0
        state.visible=false
        state.overlay=false
        state.loading=false
    },
    setMenu:(state,menus)=>{
        state.menus=menus
    },
    setActiveStatus:(state,activeUserStatus)=>{
        state.activeUserStatus=activeUserStatus
    },
    logHistory:(state,confirmation)=>{
        state.confirmation=confirmation
    },
    roleUserCount:(state,data)=>{
        state.roleUserCount=data
    },
    loginCountDate:(state,data)=>{
        state.loginDateData=data
    },
    loginCountMonth:(state,data)=>{
        state.loginMonthData=data
    },
    loginCountYear:(state,data)=>{
        state.loginYearData=data
    },
    browserCount:(state,data)=>{
        state.loginBrowserData=data
    },
    setComponentKey:(state)=>{
        state.componentKey +=1
    },
    setVisibility:(state)=>{
        state.visible=true
    },
    setOverlay:(state)=>{
        state.componentKey=0
        state.visible=false
        state.overlay=true
    },
    resetOverlay:(state)=>{
        state.componentKey +=1
        state.visible=true
        state.overlay=false
    },
    setLoading:(state)=>{
        state.loading=true
    },
    resetLoading:(state)=>{
        state.loading=false
    },
    setClientInfo:(state,data)=>{
        state.clientInfo=data
    },
};

export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}